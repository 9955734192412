import Cookies from 'js-cookie';
window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found');
}
let extreme_cookie = document.querySelector('.extreme-cookie');
let extreme_body = document.querySelector('body');
let extreme_head = document.querySelector('head');
let extreme_cookie_details = document.querySelector('.extreme-cookie-details');

if (!extreme_cookie || !extreme_cookie.classList.contains('extreme-cookie-out')) {
    extreme_body.classList.add('extreme-cookie-in');
}
// Cookie switch
document.querySelectorAll('.extreme-cookie-toggle input[type="checkbox"]').forEach(cookie_switch => {
    cookie_switch.addEventListener('change', function(event) {

        extreme_cookie.classList.add('changed');
        event.target.parentElement.classList.toggle('checked');

        axios.post('/cookie-manager/toggle', {
                name   : event.target.getAttribute('name'),
                value  : event.target.checked
            })
            .then(function (response) {
                console.log(response);

                if (response.data.hasOwnProperty('removeCookies')) {
                    extremeCookiesRemove(response.data.removeCookies);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    })
});


window.extremeCookiePanelToggle = function() {

    extreme_cookie.classList.remove('extreme-cookie-out');

    if (extreme_body.classList.contains('extreme-cookie-in')) {
        extreme_cookie.classList.add('extreme-cookie-slide-out');
        extreme_body.classList.remove('extreme-cookie-in');
    } else {
        extreme_cookie.classList.remove('extreme-cookie-slide-out');
        extreme_body.classList.add('extreme-cookie-in');
    }
}

window.extremeCookiesRemove = function(cookies) {

    cookies.forEach( function(cookie) {
        Cookies.remove(cookie.name);
        Cookies.remove(cookie.name, { domain: cookie.domain });
        Cookies.remove(cookie.name, { path: cookie.path });
        Cookies.remove(cookie.name, { domain: cookie.domain, path: cookie.path });
    });

}

window.extremeCookieDetails = function() {

    if(extreme_cookie_details.style.display != 'block') {
        extreme_cookie_details.style.display = 'block';
    } else {
        extreme_cookie_details.style.display = 'none';
    }

}

window.extremeCookieHide = function() {

    axios.post('/cookie-manager/hide')
        .then(function (response) {
            //console.log(response);
            if (response.data.hasOwnProperty('success') && response.data.success) {
                extreme_cookie.classList.add('extreme-cookie-slide-out');
                extreme_body.classList.remove('extreme-cookie-in');
            }

            if (extreme_cookie.classList.contains('changed')) {
                location.reload();
            }
          })
          .catch(function (error) {
            console.log(error);
          });

}

window.extremeCookieCodes = function() {

    axios.post('/cookie-manager/codes')
        .then(function (response) {
            console.log(response);
            if (response.data.hasOwnProperty('success') && response.data.success) {

                extreme_head.innerHTML += response.data.head;
                extreme_body.innerHTML = response.data.body + extreme_body.innerHTML;
            }

        })
        .catch(function (error) {
            console.log(error);
        }).then(function (){
        extremeCookieInit();
    });

}
